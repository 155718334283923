<template>
  <div class="app-container">
    <el-card shadow="always" style="margin-bottom: 10px">
      {{ this.generateInfo.desc }}
    </el-card>
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
    </el-form>

    <el-row :gutter="10" class="mb8">
<!--      <el-col :span="1.5">-->
<!--        <el-button-->
<!--          type="danger"-->
<!--          plain-->
<!--          icon="el-icon-delete"-->
<!--          size="mini"-->
<!--          :disabled="multiple"-->
<!--          @click="handleDelete"-->
<!--          v-hasPermi="['device:secret:remove']"-->
<!--        >废弃</el-button>-->
<!--      </el-col>-->
      <el-col :span="1.5">
        <el-button
          type="warning"
          plain
          icon="el-icon-download"
          size="mini"
          :disabled="multiple"
          @click="handleExport"
          v-hasPermi="['device:secret:export']"
        >导出</el-button>
      </el-col>
<!--      <right-toolbar :showSearch.sync="showSearch" @queryTable="getPage"></right-toolbar>-->
    </el-row>

    <el-table v-loading="loading" :data="secretList" :row-key="getRowKeys" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="设备编号" align="center" prop="deviceNumber" />
      <el-table-column label="设备密钥" align="center" prop="deviceSecret" />
<!--      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">-->
<!--        <template slot-scope="scope">-->
<!--          <el-button-->
<!--            class="text-warning"-->
<!--            size="mini"-->
<!--            type="text"-->
<!--            icon="el-icon-delete"-->
<!--            @click="handleDelete(scope.row)"-->
<!--            v-hasPermi="['device:secret:remove']"-->
<!--          >废弃</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getPage"
    />
  </div>
</template>

<script>
import { pageSecret, delSecret,generateQuery } from "@/api/device/secret";
import { reportExport } from '@/api/device/report'
import { parseTime } from '@/utils/seed'

export default {
  name: "Secret",
  data() {
    return {
      // 遮罩层
      loading: true,
      generateId: null,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 设备编号密钥对表格数据
      secretList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        generateId: null,
        ids: []
      },
      generateInfo: '',
    };
  },
  created() {
    this.generateId = this.$route.params && this.$route.params.generateId;
    this.getPage();
    this.findGenerate(this.generateId);
  },
  methods: {
    /** 查询生成详情 **/
    findGenerate(generateId) {
      generateQuery(generateId).then(res => {
        let generateDesc = '';
        this.generateInfo = res.data;
        if (res.data.type === 'DEV'){
          generateDesc = res.data.modelName + '研发第' + parseInt(res.data.numberPre.slice(-3,-1)) + '版';
        }else {
          generateDesc = res.data.companyName + res.data.modelName + '机型';
          let yearMonth = res.data.numberPre.slice(-5).slice(0,3);
          generateDesc += yearMonth.slice(0, 2) + '年' + parseInt(yearMonth.slice(2), 16) + '月';
          generateDesc += '第' + res.data.numberPre.slice(-2) + '批次';
        }
        this.generateInfo.desc = generateDesc
      })
    },
    /** 查询设备编号密钥列表 */
    getPage() {
      this.loading = true;
      this.queryParams.generateId = this.generateId;
      pageSecret(this.queryParams).then(response => {
        this.secretList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getPage();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    getRowKeys(row) {
      return row.id;
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$modal.confirm('是否确认删除数据项？').then(function() {
        return delSecret(ids);
      }).then(() => {
        this.getPage();
        this.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      this.queryParams.ids = this.ids;
      let fileName = this.generateInfo.numberPre + "_" + ((this.ids.length + '').padStart(3,'0')) + "_" + parseTime(new Date(), '{y}{m}{d}_{h}{i}{s}');
      this.download('device/secret/export', {
        ...this.queryParams
      }, `${fileName}.csv`)
    }
  }
};
</script>
